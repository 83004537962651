<template>
  <Button
    type="none"
    class="bg-secondary !px-2.5 rounded-md overflow-hidden outline-none"
    @click="() => toggleDark()"
  >
    <div class="w-full h-full flex items-center justify-center rounded-md">
      <Transition mode="out-in" v-bind="contentTransition">
        <component
          :is="isDark ? IconSun : IconMoon"
          :class="{
            'p-0.5': !isDark,
            'text-primary': !isDark,
            'text-yellow-500': isDark,
          }"
          class="size-5 stroke-2"
        />
      </Transition>
    </div>
  </Button>
</template>

<script setup>
import { useDark, useToggle } from '@vueuse/core'
import { ref } from 'vue'

import { IconMoon, IconSun } from '@tabler/icons-vue'

import { Button } from '@/components/ui/Button'

const isDark = useDark()
const toggleDark = useToggle(isDark)

const contentTransition = ref({
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-full',
  enterToClass: 'opacity-100 translate-y-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0',
  leaveToClass: 'opacity-0 -translate-y-full',
})
</script>
