import { IconLoader2 } from '@tabler/icons-vue'

export { default as Button } from './Button.js'

export const buttonsConfig = {
  classes: {
    default:
      'relative flex items-center ring-0 justify-center rounded-md transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer',
    disabled:
      'disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed',
    loading: 'cursor-wait',
  },
  loadingIcon: IconLoader2,
  icon: {
    loading: 'animate-spin',
    position: {
      left: {
        xs: '-ml-0.5 mr-1',
        sm: '-ml-0.5 mr-1',
        md: '-ml-1 mr-2',
        lg: '-ml-1 mr-2',
        xl: '-ml-1 mr-3',
      },
      right: {
        xs: '-mr-0.5 ml-1',
        sm: '-mr-0.5 ml-1',
        md: '-mr-1 ml-2',
        lg: '-mr-1 ml-2',
        xl: '-mr-1 ml-3',
      },
    },
    size: {
      xs: 'h-4 w-4',
      sm: 'h-4 w-4',
      md: 'h-4 w-4',
      lg: 'h-4 w-4',
      xl: 'h-5 w-5',
    },
  },
  styles: {
    primary:
      'bg-primary text-primary-foreground hover:bg-primary/80 focus-visible:outline-blue',
    secondary:
      'text-secondary-foreground bg-secondary focus-visible:outline-gray-300',
    danger:
      'text-white bg-rose-600 hover:bg-rose-700 focus-visible:outline-rose-600',
    outline: 'text-primary bg-transparent border',
    ghost: 'text-primary bg-transparent hover:bg-secondary',
  },
  sizes: {
    xs: 'px-2.5 py-1.5 text-xs leading-4',
    sm: 'px-3 py-2 text-sm font-medium leading-4',
    md: 'px-4 py-2 text-sm font-semibold',
    lg: 'px-6 py-3 text-base',
    xl: 'px-8 py-4 text-base leading-5',
  },
}
