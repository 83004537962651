<template>
  <label
    class="switch-container"
    :style="width ? `--width: ${width}` : ''"
    :class="{ 'switch-container--disabled': context.disabled }"
  >
    <slot name="label">
      <span
        v-if="context.title"
        class="switch-container__label"
        v-text="context.title"
      />
    </slot>
    <div
      :class="{ checked: context.value }"
      :style="`transition-duration: ${context.duration ?? 200}ms;`"
      class="switch-container__switch"
    >
      <svg
        :style="`transition-duration: ${context.duration ?? 200}ms;`"
        class="switch-container__slider"
        preserveAspectRatio="xMinYMid meet"
        viewBox="0 0 28 20"
      >
        <rect
          class="switch-rect"
          fill="white"
          height="20"
          rx="10"
          width="20"
          x="4"
          y="0"
        >
          <animate
            :ref="refs.rect"
            :dur="`${context.duration ?? 200}ms`"
            attributeName="height"
            attributeType="XML"
            begin="indefinite"
            values="20;18;20"
          />
          <animate
            :ref="refs.rect1"
            :dur="`${context.duration ?? 200}ms`"
            attributeName="x"
            attributeType="XML"
            begin="indefinite"
            values="4;0;4"
          />
          <animate
            :ref="refs.rect2"
            :dur="`${context.duration ?? 200}ms`"
            attributeName="width"
            attributeType="XML"
            begin="indefinite"
            values="20;28;20"
          />
          <animate
            :ref="refs.rect3"
            :dur="`${context.duration ?? 200}ms`"
            attributeName="y"
            attributeType="XML"
            begin="indefinite"
            values="0;1;0"
          />
        </rect>
        <svg fill="none" viewBox="0 0 20 20">
          <path
            :d="startPosition1"
            :fill="
              context.value ? 'var(--active-color)' : 'var(--inactive-color)'
            "
          >
            <animate
              id="linesd"
              :ref="refs.line1"
              :dur="`${context.duration ?? 200 ?? 200}ms`"
              :from="animated1.from"
              :to="animated1.to"
              attributeName="d"
              begin="indefinite"
              fill="freeze"
            />
          </path>
          <path
            :d="startPosition2"
            :fill="
              context.value ? 'var(--active-color)' : 'var(--inactive-color)'
            "
          >
            <animate
              :ref="refs.line2"
              :dur="`${context.duration ?? 200}ms`"
              :from="animated2.from"
              :to="animated2.to"
              attributeName="d"
              begin="indefinite"
              fill="freeze"
            />
          </path>
        </svg>
      </svg>
      <input
        class="switch-container__input"
        :checked="context.value"
        tabindex="0"
        :disabled="context.disabled"
        type="checkbox"
        @input="check($event.target.checked)"
      />
    </div>
  </label>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue'

const line1Focus =
  'M6.56666 11.0013L6.56666 8.96683L13.5667 8.96683L13.5667 11.0013L6.56666 11.0013Z'
const line2Focus =
  'M13.5582 8.96683L13.5582 11.0013L6.56192 11.0013L6.56192 8.96683L13.5582 8.96683Z'
const line1Checked =
  'M7.89561 14.8538L6.30462 13.2629L14.3099 5.25755L15.9009 6.84854L7.89561 14.8538Z'
const line2Checked =
  'M4.08643 11.0903L5.67742 9.49929L9.4485 13.2704L7.85751 14.8614L4.08643 11.0903Z'
const line1NotChecked =
  'M5.13231 6.72963L6.7233 5.13864L14.855 13.2704L13.264 14.8614L5.13231 6.72963Z'
const line2NotChecked =
  'M13.2704 5.13864L14.8614 6.72963L6.72963 14.8614L5.13864 13.2704L13.2704 5.13864Z'

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
  disabled: Boolean,
  label: {
    type: String,
    default: '',
  },
  duration: {
    type: Number,
    default: 200,
  },
})

const width = ref(props.context.width || '40px')
const emit = defineEmits(['update:model-value'])
const startPosition1 = ref('')
const startPosition2 = ref('')
const animated1 = ref({
  from: '',
  to: '',
})

const animated2 = ref({
  from: '',
  to: '',
})

const refs = {
  rect: ref(),
  rect1: ref(),
  rect2: ref(),
  rect3: ref(),
  line1: ref(),
  line2: ref(),
}

onBeforeMount(() => {
  startPosition1.value = props.context.value ? line1Checked : line1NotChecked
  startPosition2.value = props.context.value ? line2Checked : line2NotChecked
})

watch(
  () => props.context.value,
  () => transform(line1Focus, line2Focus, true),
)

function transform(line1, line2, a = false) {
  animated1.value.from = startPosition1.value
  animated1.value.to = line1
  animated2.value.from = startPosition2.value
  animated2.value.to = line2

  if (a) {
    animate('rect')
  }

  animate('line')

  setTimeout(() => {
    startPosition1.value = line1
    startPosition2.value = line2
    if (a) {
      props.context.value
        ? transform(line1Checked, line2Checked)
        : transform(line1NotChecked, line2NotChecked)
    }
  }, props.duration)
}

function animate(regex) {
  Object.keys(refs).map((k) => {
    if (k.startsWith(regex)) {
      refs[k].value.beginElement()
    }
  })
}

function check(value) {
  if (
    typeof window !== undefined &&
    window.navigator &&
    window.navigator.vibrate
  ) {
    window.navigator.vibrate(5)
  }

  props.context.node.input(value)
}
</script>
