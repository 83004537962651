import { defineComponent, h, inject, resolveDynamicComponent } from 'vue'

import { cn } from '@/utils/classes.js'

import { buttonsConfig } from '.'

export default defineComponent({
  name: 'UIButton',
  props: {
    title: {
      type: String,
      default: '',
      comment: 'Параметр используется для отображения текста внутри кнопки',
    },
    type: {
      type: String,
      default: 'primary',
    },
    tag: {
      type: String,
      default: 'button',
    },
    size: {
      type: String,
      default: 'md',
    },
    icon: {
      default: null,
    },
    loadingIcon: {
      type: Function,
      default: null,
    },
    loadingText: {
      type: String,
      default: 'Loading',
    },
    loadingClass: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { attrs, slots }) {
    const iconClass = `${buttonsConfig.icon.size[props.size]} ${
      props.isLoading && !props.icon
        ? ''
        : buttonsConfig.icon.position[props.reverse ? 'right' : 'left'][
            props.size
          ]
    }`

    function prepareLoadingIcon(disableSize = false) {
      // @ts-ignore
      return h(props.loadingIcon || buttonsConfig.loadingIcon, {
        class: [
          // @ts-ignore
          props.loadingClass || buttonsConfig.icon.loading,
          // @ts-ignore
          !disableSize ? buttonsConfig.icon.size[props.size] : '',
        ],
      })
    }

    function loadingWrapper(children) {
      return h(
        'div',
        { class: 'absolute inset-0 flex items-center justify-center' },
        [children],
      )
    }

    function renderIcon() {
      if (props.isLoading && !props.loadingIcon && !buttonsConfig.loadingIcon) {
        return loadingWrapper(props.loadingText)
      }

      if (props.icon) {
        // @ts-ignore
        return h(props.isLoading ? prepareLoadingIcon(true) : props.icon, {
          class: iconClass,
        })
      }

      if (props.isLoading && !props.icon) {
        return loadingWrapper(prepareLoadingIcon())
      }
    }

    const classes = cn(
      Object.keys(buttonsConfig).length !== 0
        ? // @ts-ignore
          `${buttonsConfig.styles[props.type]} ${buttonsConfig.sizes[props.size]} ${
            buttonsConfig.classes?.default
          } ${props.reverse ? 'flex-row-reverse' : 'flex-row'}`
        : '',
    )

    function title() {
      return props.title
        ? h(
            'span',
            { class: props.isLoading && !props.icon ? 'opacity-0' : '' },
            props.title,
          )
        : slots.default
          ? slots.default()
          : null
    }

    return () =>
      h(
        // @ts-ignore
        resolveDynamicComponent(props.tag),
        {
          // @ts-ignore
          class: `${classes} ${props.isLoading ? buttonsConfig.classes.loading : ''}`,
          onClick: (e) =>
            props.isLoading || attrs.disabled
              ? e.stopImmediatePropagation()
              : e,
        },
        {
          default: () => [renderIcon(), title()],
        },
      )
  },
})
