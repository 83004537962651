import { useAuthStore } from '@/stores/auth.js'

export default function (to, from) {
  const auth = useAuthStore()

  if (to.meta?.auth === true && !auth.isLoggedIn) {
    auth.returnUrl = to.fullPath
    return '/'
  }
}
