<template>
  <div>
    <Button
      v-if="!auth.isLoggedIn"
      tag="router-link"
      to="/login"
      type="secondary"
      title="Войти"
    />
    <router-link v-else class="lg:w-min w-full" to="/workspace">
      <Button
        class="w-full whitespace-nowrap"
        :title="auth.user?.title"
        type="secondary"
        :icon="IconStar"
      />
    </router-link>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'

import { IconStar } from '@tabler/icons-vue'

import { Button } from '@/components/ui/Button'

const auth = useAuthStore()
</script>
