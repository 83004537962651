<template>
  <div
    class="sticky top-0 z-[60] h-[60] transition-colors text-black py-4 border-white/50 dark:border-background/40 dark:text-white backdrop-blur border-gray-100 duration-1000"
    :class="{
      'bg-white/80 border-b shadow dark:bg-border/50': !withoutProfile,
    }"
  >
    <div class="container flex h-full items-center justify-between">
      <router-link class="md:block hidden" to="/">
        <img
          :src="isDark ? '/images/logo-dark.svg' : '/images/logo.svg'"
          alt="Application logo"
          class="w-[144px]"
        />
      </router-link>

      <div class="flex items-center">
        <ThemeSwitcher class="mr-2" />
        <Profile v-if="!withoutProfile" />
        <Button
          v-if="auth.isLoggedIn"
          @click="auth.logout"
          class="ml-2 !px-2.5"
          type="secondary"
        >
          <IconLogout class="w-5 h-5" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'

import { IconLogout } from '@tabler/icons-vue'

import Profile from '@/components/common/Profile.vue'
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue'
import { Button } from '@/components/ui/Button'
import useTheme from '@/core/theme.js'

const props = defineProps({
  withoutProfile: {
    type: Boolean,
    default: false,
  },
})

const isDark = useTheme()

const auth = useAuthStore()
</script>
