<template>
  <div>
    <!-- Hidden input -->
    <input
      ref="uploadInputRef"
      class="hidden peer"
      type="file"
      :multiple="multiple"
      :accept="allowedTypes"
      :disabled="availableToAdd === 0"
      @change="onInputChange"
    />
    <div
      ref="dragContainerRef"
      class="peer-disabled:cursor-not-allowed border-spacing-4 peer-disabled:grayscale peer-disabled:opacity-50 bg-white dark:bg-transparent dark:text-white flex gap-2 flex-col cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-black/20 dark:border-white dark:border-white/20 bg-transparent py-6 px-4 transition-all duration-500 hover:dark:bg-primary-950 hover:bg-primary-50 mb-2.5"
      @click="openUploadInput"
      @drop="dropHandler"
      @dragover="dragoverHandler"
    >
      <IconFileUpload class="size-10 text-primary-600 dark:text-primary-200" />
      <div class="relative z-20">
        Перетащите
        {{ fileLimit === null || fileLimit === 1 ? 'файл' : 'файлы' }} сюда или
        <span class="text-primary-600 underline underline-offset-4"
          >выберите</span
        >
      </div>
      <div
        class="text-xs text-black-600 dark:text-black-300"
        v-text="
          `Максимальный размер файла: ${props.context.maxSize ? props.context.maxSize : 100} мб`
        "
      />
    </div>

    <!-- Files wrapper -->
    <TransitionGroup
      ref="listContainerRef"
      tag="div"
      name="files-fade"
      class="relative grid gap-2"
    >
      <UploaderFileTile
        v-for="(item, i) in model"
        :key="item._id || item.id"
        :context="item"
        :draggable="props.context.enableSorting"
        @remove="remove(item.id, i)"
        @reload="reload(item._id, i)"
        @stop="stop(item._id, i)"
      />
    </TransitionGroup>
  </div>
</template>
<script setup>
import { useSortable } from '@vueuse/integrations/useSortable'
import { v4 as uuidv4 } from 'uuid'
import { computed, inject, nextTick, ref } from 'vue'
import { toast } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import { IconFileUpload } from '@tabler/icons-vue'

import useHttp from '@/core/http.js'

import UploaderFileTile from './UploaderFileTile.vue'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const listContainerRef = ref(null)
const uploadInputRef = ref(null)
const dragContainerRef = ref(null)
const fileLimit = props.context.max || null
const model = ref(props.context.value || [])
const auth = useAuthStore()
const multiple = props.context.multiple || false
const allowedTypes = props.context.accept
  ? Array.isArray(props.context.accept)
    ? props.context.accept.join(',')
    : props.context.accept
  : null
const maximumSize = props.context.maxSize * 1024 * 1024 || 100 * 1024 * 1024
const availableToAdd = computed(() => {
  return fileLimit ? fileLimit - model.value.length : 1 - model.value.length
})

const uploaderState = ref({
  error: null,
})

function checkFileType(fileType) {
  if (!allowedTypes) return true
  const typesArray = allowedTypes.split(',')

  return (
    typesArray.includes(fileType) ||
    (typesArray.includes('image/*') && fileType.startsWith('image/')) ||
    (typesArray.includes('video/*') && fileType.startsWith('video/'))
  )
}

function dragoverHandler(e) {
  e.preventDefault()
}

function extractValuesAfterSlash(inputString) {
  let parts = inputString.split(',')
  let valuesAfterSlash = parts.map((part) => part.split('/').pop())
  return valuesAfterSlash.join(', ')
}

function dropHandler(e) {
  e.preventDefault()
  const files = e.dataTransfer.files
  let filesArray = Array.from(files)
  let checkedFiles = []

  filesArray.forEach((file) => {
    const size = file.size <= maximumSize
    const type = checkFileType(file.type)
    if (size && (allowedTypes !== null ? type : true)) {
      checkedFiles.push(file)
    } else {
      if (!size) {
        uploaderState.value.error = {
          type: 'size',
          title: 'Файл слишком большой',
          message: `Максимальный размер: ${props.context.maxSize ? props.context.maxSize : 100} мб`,
        }
        return
      }
      if (!type) {
        uploaderState.value.error = {
          type: 'type',
          title: 'Неправильный тип файла',
          message: 'Доступные: ' + extractValuesAfterSlash(allowedTypes),
        }
      }
    }
  })

  if (uploaderState.value.error !== null) {
    toast.error(uploaderState.value.error.title, {
      description: uploaderState.value.error.message,
    })

    uploaderState.value.error = null
  }

  if (fileLimit) {
    checkedFiles = checkedFiles.slice(0, availableToAdd.value)
  }
  // Trigger change event on input element to add dropped files
  const fileList = new DataTransfer()
  checkedFiles.forEach((file) => {
    fileList.items.add(file)
  })
  uploadInputRef.value.files = fileList.files
  uploadInputRef.value.dispatchEvent(new Event('change'))
}

function reload(id) {
  requestQueue.retryRequest(id)
}

function stop(id, i) {
  model.value.splice(i, 1)
  props.context.node.input(model.value)
  requestQueue.cancelRequest(id)
}

async function remove(id, i) {
  await useHttp(`/files/${id}`, {
    method: 'DELETE',
  }).catch(() => {
    model.value.splice(i, 1)
    props.context.node.input(model.value)
  })
  model.value.splice(i, 1)
  props.context.node.input(model.value)
}

function openUploadInput() {
  uploadInputRef.value.click()
}

function onInputChange({ target }) {
  // For each attached file call addFile method.
  let filesArray = Array.from(target.files)
  let checkedFiles = []

  filesArray.forEach((file) => {
    const size = file.size <= maximumSize
    const type = checkFileType(file.type)
    if (size && (allowedTypes !== null ? type : true)) {
      checkedFiles.push(file)
    } else {
      if (!size) {
        uploaderState.value.error = {
          type: 'size',
          title: 'Файл слишком большой',
          message: `Максимальный размер: ${props.context.maxSize ? props.context.maxSize : 100} мб`,
        }
        return
      }
      if (!type) {
        uploaderState.value.error = {
          type: 'type',
          title: 'Неправильный тип файла',
          message: 'Доступные: ' + extractValuesAfterSlash(allowedTypes),
        }
      }
    }
  })

  if (uploaderState.value.error !== null) {
    toast.error(uploaderState.value.error.title, {
      description: uploaderState.value.error.message,
    })

    uploaderState.value.error = null
  }

  if (fileLimit) {
    checkedFiles = checkedFiles.slice(0, availableToAdd.value)
  }

  checkedFiles.map((file) => filePrepare(file))
  props.context.node.input(model.value)
  uploadInputRef.value.value = null
}

function filePrepare(file) {
  const id = uuidv4()

  file.id = id

  const fileState = {
    _id: id,
    path: null,
    size: file.size,
    name: file.name,
    mimeType: getFileType(file),
    progress: 0,
    collection: 'uploads',
    status: 'waiting',
  }

  model.value.push(fileState)
  requestQueue.addRequest(new XMLHttpRequest(), file)
}

function executeRequestsDynamic(maxParallel) {
  let currentRequests = 0
  let queue = []
  let failed = []
  let pending = {}
  let isExecuting = false

  function executeNext() {
    if (currentRequests < maxParallel && queue.length > 0) {
      props.context.node.emit('loading', true)
      isExecuting = true
      currentRequests++
      const { request, file, id } = queue.shift()
      pending[id] = request

      const fileState = model.value.find((item) => item._id === file.id)
      fileState.status = 'pending'

      request.onreadystatechange = function () {
        if (request.readyState == 4) {
          currentRequests--
          if (request.status >= 200 && request.status < 300) {
            executeNext()
          } else {
            failed.push({ request, file })
            fileState.status = 'error'
            executeNext()
          }
        }
      }

      request.open('POST', '/api/files/upload', true)
      if (auth?.token) {
        request.setRequestHeader('Authorization', `Bearer ${auth.token}`)
      }
      request.setRequestHeader('Accept', 'application/json')
      request.timeout = 60 * 60 * 24 * 1000

      request.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          // Обновляем прогресс бар
          fileState.progress = ((e.loaded / e.total) * 100).toFixed(0)
        }
      }

      request.onload = () => {
        delete pending[id]
        const { media } = JSON.parse(request.responseText)
        if (request.status == 200) {
          // Файл успешно загружен
          fileState.status = 'success'
          fileState.id = media.id
          fileState.path = media.path
        } else {
          fileState.status = 'error'
          // Обработка ошибок
          fileState.message = 'Произошла ошибка при загрузке файла'
        }
      }

      request.onerror = () => {
        if (request.responseText) {
          const { message } = JSON.parse(request.responseText)
          fileState.message = message
        } else {
          fileState.message = 'Произошла ошибка при отправке запроса'
        }
        fileState.status = 'error'
      }

      const formData = new FormData()
      formData.append('file', file)

      request.send(formData)
    } else if (currentRequests === 0 && queue.length === 0) {
      isExecuting = false
      props.context.node.emit('loading', false)
    }
  }

  return {
    addRequest: (request, file) => {
      const id = file.id
      queue.push({ request, file, id })
      executeNext()
    },
    cancelRequest: (id) => {
      pending[id].abort()
    },
    retryRequest: (id) => {
      const retryItemIndex = failed.findIndex((item) => item.id === id)
      const retryRequest = failed[retryItemIndex]
      failed.splice(retryItemIndex, retryItemIndex, 1)
      if (retryRequest) {
        queue.push({
          request: retryRequest.request,
          file: retryRequest.file,
          id,
        })
        executeNext()
      }
    },
  }
}

const requestQueue = executeRequestsDynamic(+props.context.maxParallel || 1)

useSortable(listContainerRef, model, {
  sort: props.context.enableSorting || false,
  ghostClass: 'ring-1',
  animation: 150,
  onUpdate: (e) => {
    nextTick(() => {
      props.context?.node?.input(model.value)
    })
  },
})

function getFileType(file) {
  const mimeTypes = {
    image: /^image\//,
    video: /^video\//,
    audio: /^audio\//,
    archive: ['application/zip', 'application/x-rar-compressed'],
    pdf: ['application/pdf'],
    excel: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    word: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    ppt: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  }

  const fileType = Object.keys(mimeTypes).find((type) => {
    if (Array.isArray(mimeTypes[type])) {
      return mimeTypes[type].includes(file.type)
    } else {
      return mimeTypes[type].test(file.type)
    }
  })

  if (fileType) {
    return fileType
  } else {
    return 'common file'
  }
}
</script>

<style scoped>
.sortable-chosen {
  cursor: grab;
}

.sortable-drag {
  opacity: 0;
}

.files-fade-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.files-fade-enter-from {
  opacity: 0;
  transform: scaleY(0.01) translate(0, 10px);
}
</style>
