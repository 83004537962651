import { useHead } from 'unhead'
import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth.js'

import Auth from '@/layouts/auth.vue'
import Default from '@/layouts/default.vue'
import Empty from '@/layouts/empty.vue'
import auth from '@/middleware/auth.js'
import dev from '@/middleware/dev.js'
import guest from '@/middleware/guest.js'
import isBrowser from '@/utils/is-browser.js'

let routes = [
  {
    path: '/',
    component: () => import('@/pages/catalog/index.vue'),
    name: 'index',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/login',
    component: () => import('@/pages/login.vue'),
    name: 'login',
    meta: {
      layout: Auth,
      title: 'Авторизация',
      guest: true,
    },
    beforeEnter: (t, f) => guest(t, f),
  },
  {
    path: '/magic',
    component: () => import('@/pages/magic.vue'),
    name: 'magic',
    meta: {
      layout: Empty,
      guest: true,
    },
  },
  {
    path: '/test',
    component: () => import('@/pages/test.vue'),
    name: 'test',
    meta: {
      layout: Empty,
      dev: true,
    },
    beforeEnter: (t, f) => dev(t, f),
  },
  {
    path: '/competitions/:id',
    component: () => import('@/pages/catalog/[id].vue'),
    name: 'competitions-id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/profile/',
    meta: {
      layout: Default,
      auth: true,
    },
    children: [
      {
        path: 'settings',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-settings',
        meta: {
          title: 'Настройки профиля',
        },
      },
      {
        path: 'favorites',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-favorites',
      },
      {
        path: 'works',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-works',
      },
    ],
  },
  {
    path: '/workspace',
    meta: {
      layout: Default,
      auth: true,
      title: 'Рабочее пространство',
    },
    name: 'workspace-index',
    component: () => import('@/pages/workspace/index.vue'),
    beforeEnter: (t, f) => auth(t, f),
    children: [
      {
        path: 'works-form',
        component: () => import('@/pages/workspace/[page]/works-form.vue'),
        name: 'workspace-index-works-form',
      },
      {
        path: 'reviews-form',
        component: () => import('@/pages/workspace/[page]/reviews-form.vue'),
        name: 'workspace-index-reviews-form',
      },
      {
        path: 'fields',
        component: () => import('@/pages/workspace/[page]/fields.vue'),
        name: 'workspace-index-fields',
      },
      {
        path: 'reviews',
        component: () => import('@/pages/workspace/[page]/reviews/index.vue'),
        name: 'workspace-index-reviews',
      },
      {
        path: 'reviews/:id',
        component: () => import('@/pages/workspace/[page]/reviews/[id].vue'),
        name: 'workspace-index-reviews-id',
      },
    ],
  },
]

export default function () {
  const router = createRouter({
    history: isBrowser ? createWebHistory('/') : createMemoryHistory('/'),
    routes: [
      ...routes,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        name: '404',
        meta: {
          layout: Default,
        },
      },
    ],
  })

  const middleware = import.meta.glob('@/middleware/*.js')

  for (const path in middleware) {
    middleware[path]().then((m) => {
      router.beforeEach((t, f) => m.default(t, f))
    })
  }

  return router
}
