/**
 * @param {Number} count
 * @param {Array} words
 * @param {Boolean} extend
 * @return {String}
 */
export function plural(count, words, extend = false) {
  const cases = [2, 0, 1, 1, 1, 2]
  const word =
    words[
      count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
    ]
  return extend ? `${count} ${word}` : word
}
