const fade = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}
const fadeSlideLeft = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-x-full',
  enterToClass: 'opacity-100 translate-x-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-x-0',
  leaveToClass: 'opacity-0 -translate-x-full',
}
const fadeSlideLeftToggle = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-x-full',
  enterToClass: 'opacity-100 translate-x-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-x-0',
  leaveToClass: 'opacity-0 translate-x-full',
}
const fadeSlideRight = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 -translate-x-full',
  enterToClass: 'opacity-100 translate-x-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0 translate-x-full',
}

const fadeScale = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
}

const bottomSheetSlide = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'translate-y-full',
  enterToClass: 'translate-y-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'translate-y-0',
  leaveToClass: 'translate-y-full',
}

const fadeScrim = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: '!opacity-0',
  enterToClass: '!opacity-80',
  leaveActiveClass: 'ease-in duration-300',
  leaveFromClass: 'opacity-80',
  leaveToClass: 'opacity-0',
}
const searchTransition = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: '-translate-y-full',
  enterToClass: 'opacity-100 translate-y-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'translate-y-0',
  leaveToClass: '-translate-y-full',
}

export {
  fade,
  fadeScale,
  fadeSlideLeft,
  fadeSlideRight,
  fadeScrim,
  searchTransition,
  fadeSlideLeftToggle,
  bottomSheetSlide,
}
